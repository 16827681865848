<template>
  <section class="antar-barang">
    <div class="container-scroller">
      <div class="page-header">
        <h3 class="page-title">
          &nbsp;
        </h3>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
            <li class="breadcrumb-item">. . .</li>
            <li class="breadcrumb-item next-breadcrumb" aria-current="page"><router-link class="nav-link" :to="`/operasional/antar-barang/pengukuran-paket/${params.uuid}`"><span class="menu-title">Antar Barang - Pengukuran Paket</span></router-link></li>
            <li class="breadcrumb-item next-breadcrumb" aria-current="page"><router-link class="nav-link" :to="`/operasional/antar-barang/pengiriman-paket/${params.uuid}`"><span class="menu-title">Antar Barang - Pengiriman Paket</span></router-link></li>                        
            <li class="breadcrumb-item active" aria-current="page">Pembayaran</li>						
          </ol>
        </nav>
      </div>          
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <h2 class="col-xl-8 col-lg-6 mx-auto text-center pb-5" style="color: #00b050;font-weight: 800;">Antar Barang</h2>            
            <div class="col-xl-8 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-5">
                <div class="card pb-4">
									<div class="brand-logo text-center">
										<img class="pt-5" src="@/assets/images/Logo.png">
									</div>
									<div class="row pl-4">
										<h6 class="font-weight-bold col-lg-12 text-center">Terima Kasih
                      Telah memilih layanan Kurir Paket
                      Kurir kami akan segera
                      Pickup Barang Anda</h6>
									</div>																											        
                </div>

                <form class="pt-3">
                  <div class="mt-3">
                    <template v-if="alert.status != 0">
                      <h4 class="pt-3 text-center" style="color:#e91e63;">{{ alert.message }}</h4>
                    </template>
                    <b-button ref="containerButton" class="w-100 btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" style="background-color:#d2ddf7;" variant="info" >Lihat Status</b-button>
                    <b-button ref="containerButton" class="w-100 btn btn-block btn-white btn-lg font-weight-medium auth-form-btn" style="background-color:#d2ddf7;color:#000" variant="info" >Home</b-button>                                        
                    <!-- <router-link class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" to="/">SIGN IN</router-link> -->
                  </div>
                </form>                



              </div>
            </div>
          </div>
      </div>
      <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>

</template>

<script>
import axios from 'axios'
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'login',
  data(){
    return{
      passwordHidden: false,
      params:{
        uuid: this.$route.params.uuid
      },
      unitDimension : "cm",
      cubicM: "\u33A5",
      errorMassage:0,
      uuid_package_sizes : '',
      form:{
        route: '',
        route_title: 'pengukuran_paket',        
        uuid_units_of_measurements : '',
        uuid_m_load_times : '',
        weight : 0,
        long : 0,
        wide : 0,
        high : 0,
        volume : 0,        
        price_dimension: 0,
        price_weight: 0,
        price_time: 0,
        created_by: localStorage.getItem("uuid"),
      },
      alert:{
        status: 0,
        message:'',
      },
      data: [],
      dataOption1: [],
      optSelect: '',
      optionsRadio: [],
      selected: '', 
    }
  },
  methods:{
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),    
    getVolume: function() {
      let dataVolume = {
        long : this.form.long,
        wide : this.form.wide,
        high : this.form.high,
        uuid_package_sizes: this.uuid_package_sizes
      }

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
      axios.post(`${packageStore.mainApi}/api/web/operational/antar-barang/count-dimensions`,dataVolume)
      .then((response) => {
        this.form.volume = response.data.m3
        this.form.price_dimension = response.data.result_price
        console.log(response.data);        
      })      
    },
    getWeight: function() {
      let dataWeight = {
        weight : this.form.weight,
        uuid_units_of_measurements : this.optSelect,
        uuid_package_sizes: this.uuid_package_sizes
      }

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
      axios.post(`${packageStore.mainApi}/api/web/operational/antar-barang/count-weight`,dataWeight)
      .then((response) => {
        console.log(response.data);
        // this.form.volume = response.data.m3
        this.form.price_weight = response.data.result_price
      })      
    },    
    getLoadTime: function (search, page, limit) {
      // let loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.containerMain,
      //   canCancel: false,
      //   onCancel: this.onCancel,
      // })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/master/load-time?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)
			.then((response) => {
        let opt = response.data.result.data
        opt.forEach(element => {
          let pushData = {
            value : element.uuid,
            text : element.name
          }
          this.optionsRadio.push(pushData)
        });
			})	
    },    
    getWeightIndicator: function () {
      // let loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.containerMain,
      //   canCancel: false,
      //   onCancel: this.onCancel,
      // })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
      axios.get(`${packageStore.mainApi}/api/web/reference/units-of-measurement/byType/tuQpZRqQWzlvGnh`,packageGlobals.configAxios)
			.then((response) => {
        // this.dataOptionServices = 
        let opt = response.data.result.data
        opt.forEach(element => {
          let pushData = {
            value : element.uuid,
            text : element.name
          }
          this.dataOption1.push(pushData)
          this.optSelect = 'sqSj6F4wUUWuZqd'
        });
			})							
    },            
    getData: function (uuid) {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/operational/antar-barang/${uuid}`,packageGlobals.configAxios)
			.then((response) => {
        this.uuid_package_sizes = response.data.result.uuid_package_sizes
        this.form.route = '/operasional/antar-barang/pengukuran-paket/'+this.params.uuid        
        this.form.uuid_units_of_measurements = response.data.result.uuid_units_of_measurements
        this.form.uuid_m_load_times = response.data.result.uuid_m_load_times
        this.form.weight = response.data.result.weight
        this.form.long = response.data.result.long
        this.form.wide = response.data.result.wide
        this.form.high = response.data.result.high
        this.form.volume = response.data.result.volume
        this.form.price_dimension = response.data.result.price_dimension
        this.form.price_weight = response.data.result.price_weight                        
			})      
    },      
    // onNext(evt) {
    onNext() {      
      this.form.uuid_units_of_measurements = this.optSelect
      this.form.uuid_m_load_times = this.selected
      console.log(this.form);
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
      axios.put(`${packageStore.mainApi}/api/web/operational/antar-barang/${this.params.uuid}`,this.form)
      .then((response) => {
        this.data = response
        this.$router.push(`/operasional/antar-barang/pengiriman-paket/${this.params.uuid}`)
      })      
    }
  },
  mounted() {
    this.getData(this.params.uuid)    
		this.getWeightIndicator()
    this.getLoadTime('-','1','999')
    // this.getShippingMethod('-',1,999)    
  }  
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}

.next-breadcrumb a
{
	padding-top: 0px;
}

.custom-control-label{
  padding-top: 4px;
  font-size: 16px!important;
}

.input-group-text{
  font-weight: bold;
}

.auth .auth-form-light select
{
  color:black;
}
</style>